<template>
    <v-card :max-width="maxWidth" flat color="transparent">

        <acs-cart-alert class="mx-2" />
        <acs-order-list class="mx-2" :slug="salepoint.slug" />

        <v-card-text v-if="running" class="px-2">
            <acs-slot-lock v-model="wait" @close="wait = false" />
            <acs-slot-survey v-if="surveybottom === false" :slug="salepoint.slug" class="mt-6" @hide="surveybottom=true" @survey="doSurvey" />
        </v-card-text>

        <acs-slot-call
            v-model="show"
            :type="type" />

        <v-container class="pa-3 pt-0">
            <v-row>
                <v-col cols="6" class="pa-2">
                    <acs-slot-btn
                        :to="{ name: 'menulist' }"
                        :label="$t('salepointtable.menu')"
                        :service="{
                            name: salepoint.service_menu_name,
                            name_translated: salepoint.service_menu_name_translated
                        }"
                        icon="$vuetify.icons.tableMenu" />
                </v-col>
                <v-col cols="6" v-if="!callIgnore" class="pa-2">
                    <acs-slot-call-btn
                        v-model="showCall"
                        color="primary"
                        @selected="doShow"
                        :service="callService"
                        :disabled="wait || callDisabled" />
                </v-col>
                <v-col cols="6" v-if="!payIgnore" class="pa-2">
                    <acs-slot-btn
                        :to="{ name: 'pay' }"
                        :label="$t('pay.btn')"
                        :service="payService"
                        color="green lighten-5 green--text"
                        icon="$vuetify.icons.payBill" />
                </v-col>
                <v-col cols="6" v-if="!billIgnore" class="pa-2">
                    <acs-slot-bill
                        v-model="showBill"
                        color="primary"
                        @selected="doShow"
                        :service="billService"
                        :disabled="wait || billDisabled" />
                </v-col>
                <v-col cols="6" class="pa-2">
                    <acs-base-qrcode-btn @input="onScan">
                        <template #button="{ on }">
                            <acs-slot-btn
                                v-on="on"
                                :label="$t('salepointtable.newqr')"
                                icon="$vuetify.icons.baseQr" />
                        </template>
                    </acs-base-qrcode-btn>
                </v-col>
            </v-row>
        </v-container>
        <div class="d-flex px-2">
            <acs-slot-survey v-if="surveybottom" :slug="salepoint.slug" class="mt-0" @survey="doSurvey" :close="false" />
        </div>

        <acs-salepoint-socials class="d-flex flex-column" />
    </v-card>
</template>

<script>
/* eslint-disable eqeqeq */
import AcsSlotCall from '@/components/slot/AcsSlotCall'
import AcsSlotLock from '@/components/slot/AcsSlotLock'
import AcsSlotBill from '@/components/slot/AcsSlotBill'
import AcsSlotCallBtn from '@/components/slot/AcsSlotCallBtn'
import AcsSlotBtn from '@/components/slot/AcsSlotBtn'
import AcsSlotSurvey from '@/components/slot/AcsSlotSurvey'
import AcsBaseQrcodeBtn from '@/shared/components/base/AcsBaseQrcodeBtn'
import AcsSalepointSocials from '@/components/salepoint/AcsSalepointSocials'
import AcsCartAlert from '@/components/cart/AcsCartAlert'
import AcsOrderList from '@/components/order/AcsOrderList'
import moment from 'moment'

export default {
    name: 'acs-slot',
    props: {
        maxWidth: { type: [String, Number] }
    },
    data: () => ({
        show: false,
        showCall: false,
        showBill: false,
        wait: false,
        type: 'call',
        surveybottom: true
    }),
    components: {
        AcsBaseQrcodeBtn,
        AcsSlotBtn,
        AcsSlotSurvey,
        AcsSlotCall,
        AcsSlotCallBtn,
        AcsSlotLock,
        AcsSlotBill,
        AcsSalepointSocials,
        AcsCartAlert,
        AcsOrderList
    },
    computed: {
        connected() {
            return this.$store.getters['socket/connected']
        },
        language() {
            return this.$store.getters['ln/current']
        },
        running() {
            // pour que lorsque le progress du lock, une fois fini,
            // ne ferme pas automatiquement la popup
            // sauf si la team a pris l'appel
            if (!this.$store.getters['lock/running'] && this.$store.getters['lock/validated']) {
                return false
            }
            return this.wait || this.$store.getters['lock/running']
        },
        salepoint() {
            return this.$store.getters['sp/current']
        },
        user() {
            return this.$store.getters['auth/user']
        },
        slot() {
            return this.$store.getters['sp/slot']
        },
        callService() {
            return this.salepoint.services.find(s => s.type === 'call')
        },
        callDisabled() {
            const s = this.callService
            return !s || s.disabled || !this.connected || this.sessionTimedOut || this.closed
        },
        callIgnore() {
            const s = this.callService
            return !s || s.disabled
        },
        billService() {
            return this.salepoint.services.find(s => s.type === 'bill')
        },
        billDisabled() {
            const s = this.billService
            return !s || s.disabled || !this.connected || this.sessionTimedOut || this.closed
        },
        billIgnore() {
            const s = this.billService
            return !s || s.disabled
        },
        payService() {
            return this.salepoint.services.find(s => s.type === 'pay')
        },
        payIgnore() {
            const s = this.payService
            return !s || s.disabled
        },
        closed() {
            return this.salepoint.schedules && !this.salepoint.schedules.currently_open
        },
        sessionTimedOut() {
            return !this.$store.getters['out/active']
        }
    },
    watch: {
        show(v) {
            if (v) {
                this.wait = true
                if (!this.running) {
                    this.$store.dispatch('lock/start', { duration: this.$config.salepoint.serviceinterval })
                }
            }
        },
        running(v) {
            if (!v) {
                this.wait = false
                this.surveybottom = true
            }
        }
    },
    async mounted() {
        this.$store.dispatch('socket/start', { salepoint_id: this.salepoint.id })

        if (this.running) {
            this.wait = true
        } else if (this.slot.locked_at) {
            this.$store.dispatch('lock/start', {
                validated: this.slot.lock_validated,
                duration: this.$config.salepoint.serviceinterval,
                start: moment().diff(moment(this.slot.locked_at).utc(), 'second')
            })
            this.$nextTick(() => this.wait = this.running)
        }

        await this.$store.dispatch('out/check', { slug: this.salepoint.slug, table: this.slot.slug, token: this.$route.query.t })
        this.$store.dispatch('out/start')
    },
    beforeDestroy() {
        this.$store.dispatch('out/end')
        this.$store.dispatch('socket/stop')
    },
    sockets: {
        error(err) {
            const type = err?.error?.code
            const createdAt = err?.error?.created_at

            if (type === 'SlotLocked') {
                if (this.salepoint.calls_active) {
                    return
                }
                return this.$store.dispatch('lock/start', {
                    duration: this.$config.salepoint.serviceinterval,
                    start: moment().diff(moment(createdAt).utc(), 'second')
                })
            }
            if (['SlotDisabled', 'SalepointClosed'].indexOf(type) !== -1) {
                this.$store.commit('sp/updateService', { type: this.type, disabled: true })
                this.surveybottom = true
            }
            this.$err({
                status: err.code,
                message: err.message,
                response: { data: err }
            })
        },

        calladded(data) {
            if (data.slot_id !== this.slot.id || ['transaction', 'order'].indexOf(data.call.type) !== -1) {
                return
            }
            this.$store.dispatch('lock/start', { duration: this.$config.salepoint.serviceinterval })
            this.wait = true
        },

        callvalidated(data) {
            if (data.slot_id !== this.slot.id || ['transaction', 'order'].indexOf(data.call.type) !== -1) {
                return
            }
            // pour empêcher de rappeler le service alors qu'il vient
            // de valider l'appel
            this.showCall = false
            this.showBill = false

            this.$store.dispatch('lock/start', {
                validated: true,
                duration: this.$config.salepoint.serviceinterval
            })
            this.wait = true
        },

        callstoggle(data) {
            this.$store.commit('sp/updateService', { id: data.id, disabled: data.disabled })
        }
    },
    methods: {
        doShow(data) {
            const type = data.type
            this.type = type
            this.surveybottom = this.type === 'call'
            this.$store
                .dispatch('sp/call', {
                    socket: this.$socket,
                    salepoint_id: this.salepoint.id,
                    slot_id: this.slot.id,
                    call: {
                        type: this.type,
                        lang: this.language,
                        message: data.message,
                        unassigned: false,
                        validated: false,
                        user: {
                            id: this.user.id || '1', // anonymous user id
                            alias: this.user.alias || this.$i18n.t('user.anonymous')
                        },
                        slot: {
                            id: this.slot.id,
                            name: this.slot.name,
                            zone: this.slot.zone
                        }
                    }
                })
        },

        async onScan(url) {
            window.location.href = url
        },

        doSurvey() {
            this.$router.push({ name: 'survey', params: { slot: this.slot.slug } })
        }
    }
}
</script>
