<template>
    <v-list class="pt-0">
        <v-toolbar dense flat class="mb-3">
            <acs-base-ln-selector />
            <v-spacer />
            <acs-base-doc-btn v-if="!isOnlyTerminal" :href="$config.links.docUser" />
            <v-btn icon text @click="show = false" color="primary">
                <v-icon>$vuetify.icons.baseMenuRight</v-icon>
            </v-btn>
        </v-toolbar>

        <v-list-item class="pa-4" v-if="salepoint.id && !$route.meta.dense" exact :to="{ name: getContextRouteName('salepoint') }">
            <v-list-item-avatar size="48" v-if="salepoint.links_logo">
                <v-img :src="salepoint.links_logo" />
            </v-list-item-avatar>
            <v-list-item-content>
                <v-list-item-title class="acs--title">{{ salepoint.name }}</v-list-item-title>
            </v-list-item-content>
        </v-list-item>

        <template v-if="!isOnlyTerminal">

            <h5 class="pa-4 pb-0" v-if="user.id">{{ username }}</h5>
            <h5 class="pa-4 pt-0">{{ user.email }}</h5>
            <v-alert text tile :value="!!roles.length" color="primary">
                <ul>
                    <li v-for="role in roles" :key="role">{{ $t(`shared-front:roles.${role}`) }}</li>
                </ul>
            </v-alert>

            <template v-for="(item, index) in items">
                <v-divider v-if="item.divider" :key="`1-${index}`" />
                <acs-base-qrcode-btn v-else-if="item.scan" @input="onScan" :key="`2-${index}`">
                    <template #button="{ on }">
                        <v-list-item v-on="on" class="orange lighten-5 orange--text">
                            <v-list-item-action>
                                <v-icon color="orange">{{ item.icon }}</v-icon>
                            </v-list-item-action>
                            <v-list-item-content>
                                <v-list-item-title>{{ $t(item.text) }}</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </template>
                </acs-base-qrcode-btn>
                <v-list-item v-else
                    :key="`3-${index}`"
                    :to="item.to"
                    :href="item.href || undefined"
                    exact
                    @click="(item.click && item.click(item)) || null">
                    <v-list-item-action>
                        <v-icon>{{ item.icon }}</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title>{{ $t(item.text) }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </template>
        </template>
    </v-list>
</template>

<script>
import AcsBaseQrcodeBtn from '@/shared/components/base/AcsBaseQrcodeBtn'
import AcsBaseLnSelector from '@/shared/components/base/AcsBaseLnSelector'
import AcsBaseDocBtn from '@/shared/components/base/AcsBaseDocBtn'
import RouteMixin from '@/mixins/RouteMixin'

export default {
    name: 'acs-base-menu',
    components: { AcsBaseQrcodeBtn, AcsBaseLnSelector, AcsBaseDocBtn },
    props: {
        value: { type: Boolean }
    },
    mixins: [RouteMixin],
    computed: {
        show: {
            get() {
                return this.value
            },
            set(v) {
                this.$emit('input', v)
            }
        },
        salepoint() {
            return this.$store.getters['sp/current']
        },
        user() {
            return this.$store.getters['auth/user']
        },
        username() {
            if (!this.user) {
                return ''
            }
            const n = [this.user.firstname, this.user.lastname].filter(v => !!v).join(' ')
            return n || this.user.alias
        },
        roles() {
            if (!this.salepoint?.id || !this.user?.roles) {
                return []
            }
            return this.user.roles.filter(r => r.tenant_id === this.salepoint.id).map(r => r.role)
        },
        items() {
            const query = {
                b: 'user',
                back: this.$route.path
            }
            const querystring = Object.entries(query).map(o => `${o[0]}=${o[1]}`).join('&')

            const menu = [
                { divider: true },
                { scan: true, icon: '$vuetify.icons.baseQr', text: 'qrcodebtn.title' }
            ]
            if (!this.$route.meta.dense) {
                if (!this.user.id) {
                    menu.push(
                        { divider: true },
                        { to: { name: 'home', query: query }, text: 'home.submit' },
                        { href: `${this.$config.client}/account/signin?${querystring}`, text: 'home.createAccount' },
                        { divider: true },
                        { click: this.logout, icon: '$vuetify.icons.logout', text: 'user.logout' }
                    )
                }
            }
            if (this.user.id) {
                menu.push(
                    { divider: true },
                    { href: this.$config.client, icon: '$vuetify.icons.account', text: 'user.account' },
                    { click: this.logoutUser, icon: '$vuetify.icons.logout', text: 'user.logoutbtn' }
                )
                if (this.isOnlyTeam) {
                    menu.push(
                        { divider: true },
                        { href: `${this.$config.team}/${this.salepoint.slug}/calls`, icon: '$vuetify.icons.baseMenuRight', text: 'isOnlyTeam.backTeamFront' },
                        { click: this.showTicketX, icon: '$vuetify.icons.ticketX', text: 'ticket.x' }
                    )
                }
            }
            return menu
        },
        isOnlyTeam() {
            return this.$store.getters['auth/isOnly'](this.salepoint.id, 'team')
        },
        isOnlyTerminal() {
            return this.$store.getters['auth/isOnly'](this.salepoint.id, 'terminal')
        }
    },
    methods: {

        onScan(url) {
            window.location.href = url
        },

        survey() {
            this.show = false
            if (!this.salepoint.id) {
                return this.$router.push({ name: 'home' })
            }
            this.$router.push({ name: 'survey', params: { slug: this.salepoint.slug } })
        },

        showTicketX() {
            this.show = false
            this.$store.commit('team/ticketPrint', { show: true })
        },

        logout() {
            this.show = false
            this.$router.push({ name: 'home' })
        },

        logoutUser() {
            return this.$store.dispatch('auth/logout')
                .then(() => {
                    this.$store.commit('usr/reset')
                    this.show = false
                    window.location.reload()
                })
        }
    }
}
</script>

<style lang="sass">
.acs--title
    font-family: "Red Hat Display", "Roboto", sans-serif
    font-style: normal
    font-weight: normal
    font-size: 20px
    line-height: 24px
    letter-spacing: 0.15px
    white-space: normal

</style>
